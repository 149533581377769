<template>
  <div class="fullheight">
    <div class="content-header flex-row flex-row-between">
      <h1>{{tituloComponente}}</h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row">
          <i class="icon-arrow-left"></i>
          <span @click="volverAtras">Volver atrás</span>
        </div>
        <button v-if="!lineaProductoSelected" class="btn-primary animated" @click="agregarLineaProducto">Guardar</button>
        <button v-if="lineaProductoSelected && !view" class="btn-primary animated" @click="editar">Guardar</button>
        <button v-if="view" class="btn-primary animated" @click="cambiarAeditar">Editar</button>
      </div>
    </div>

    <div class="content-content flex-column flex-column-left flex-column-top">
      <form class="form-container fullwidth">

        <div class="flex-row flex-left flex-row-between">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container">
            <label class="form-field-indicador" for="tipo-usuario-selected-nombre"
                   v-show="lineaProducto.nombre !== ''">Nombre:</label>
            <input v-model="lineaProducto.nombre" v-validate="'required'" id="tipo-usuario-selected-nombre" name="Nombre" :disabled='view'
                   class="form-field form-field-nomargin" :class="{error: errors.has('Nombre'), 'field-complete': lineaProducto.nombre !== ''}"
                   type="text" placeholder="Nombre" maxlength="30">
          </div>

        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import ajax from '@/ajax'
import ConstantsHelper from '@/ConstantsHelper'

export default {
  name: 'crearOEditar',
  props: ['view', 'edit', 'agregar', 'lineaProductoSelected'],
  data () {
    return {
      lineaProducto: {
        id: '',
        nombre: '',
        mostrarMenu: false
      }
    }
  },
  methods: {
    volverAtras () {
      EventBus.$emit('toggleShow', true)
      this.initLineaProducto()
      this.$emit('mostrarListado')
    },
    initLineaProducto () {
      this.lineaProducto = {
        id: '',
        nombre: '',
        mostrarMenu: false
      }
    },
    agregarLineaProducto () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('POST', ConstantsHelper.getURLs().v1 + 'productos-lineas.php', { lineaProducto: this.lineaProducto, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.lineaProducto.id = response.id_inserted
                this.lineaProducto.state = 1
                EventBus.$emit('lineaProductoAgregado', this.lineaProducto)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initLineaProducto()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    editar () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('PUT', ConstantsHelper.getURLs().v1 + 'productos-lineas.php', { lineaProducto: this.lineaProducto, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.lineaProducto.mostrarMenu = false
                EventBus.$emit('lineaProductoEditado', this.lineaProducto)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initLineaProducto()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })

              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    cambiarAeditar () {
      this.$emit('editar', this.lineaProducto)
    },

    toggleHeader () {
      if (this.agregar || this.view || this.edit) {
        EventBus.$emit('toggleShow', false)
      } else {
        EventBus.$emit('toggleShow', true)
      }
    }
  },
  computed: {
    tituloComponente () {
      if (this.view) {
        return 'Visualizar linea de producto'
      } else {
        return (this.lineaProductoSelected) ? 'Editar linea de producto' : 'Agregar linea de producto'
      }
    }
  },
  watch: {
    lineaProductoSelected: {
      handler () {
        if (this.lineaProductoSelected) {
          this.lineaProducto = this.lineaProductoSelected
        } else {
          this.initLineaProducto()
        }
      }
    },
    agregar () {
      this.toggleHeader()
    },
    view () {
      this.toggleHeader()
    },
    edit () {
      this.toggleHeader()
    }
  }
}
</script>
